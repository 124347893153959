import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormInput } from '@app/shared/models/form-input';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelperService } from '@app/shared/services/helper.service';
import { getErrorMessage } from '@app/shared/helpers/validator';
import {
  checkPhoneNumberValid,
  checkWhitespace,
  validatePhoneNumberRegistration
} from '@app/modules/registration/customValidators';
import { clearError } from '@app/shared/helpers/clearError';
import { Subscription } from 'rxjs';
import { RegistrationService } from '@app/modules/registration/services/registration.service';

@Component({
  selector: 'app-vs-phone-number',
  templateUrl: './vs-phone-number.component.html',
  styleUrls: ['./vs-phone-number.component.scss']
})
export class VsPhoneNumberComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() config: FormInput;
  @Input() registrationForm: FormGroup;
  @ViewChild('phone_number_input') phone_number_input: ElementRef;
  showValidationLabels = false;
  selectedDialCode: { key: string; value: string; code?: string };
  phoneNumberForm: FormGroup;
  focusedInput: HTMLInputElement;

  phoneNumberChangesSub: Subscription = null;
  constructor(
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private registrationService: RegistrationService
  ) {
    this.phoneNumberForm = this.formBuilder.group({
      dialCode: new FormControl('', Validators.required),
      number: new FormControl('', [
        Validators.required,
        Validators.compose([/* checkWhitespace(), */ checkPhoneNumberValid()])
      ])
    });
  }

  ngOnInit(): void {
    this.registrationForm.setControl(this.config.key, this.phoneNumberForm);
    this.selectedDialCode = this.config.options.find(item => item.key === 'de');
    this.phoneNumberForm.get('dialCode').setValue(this.selectedDialCode.code);

    this.phoneNumberForm.get('dialCode').valueChanges.subscribe(value => {
      this.selectedDialCode = this.config.options.find(item => item.code === value);
    });

    this.phoneNumberChangesSub = this.phoneNumberForm.valueChanges.subscribe(() => {
      const emailControl = this.registrationForm.get('email');
      const phoneNumberControl = this.phoneNumberForm.get('number');

      if (emailControl && phoneNumberControl) {
        clearError(emailControl, 'emailOrNumberExists');
        clearError(phoneNumberControl, 'emailOrNumberExists');
      }
    });
  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
  onBlur(): void {
    if (this.config.validators) {
      this.showValidationLabels = true;
      this.phoneNumberForm.get('number').markAsTouched();
      this.phoneNumberForm.get('number').updateValueAndValidity();
    }
  }
  onFocusInOut(action: string, event: FocusEvent): void {
    const targetInput = event.target as HTMLInputElement;
    this.helperService.setCurrentInputValue(targetInput.value);

    if (this.config.validators) {
      this.showValidationLabels = action === 'in' || this.phoneNumberForm.get('number').value;
    }
    const dialCodeList = this.registrationService.dialCodeList$;
    const splitedPhoneNumber = validatePhoneNumberRegistration(targetInput.value, dialCodeList);

    if (splitedPhoneNumber.phoneNumber) {
      this.phoneNumberForm.get('number').setValue(splitedPhoneNumber.phoneNumber);
    }
    if (splitedPhoneNumber.dialCode) {
      this.phoneNumberForm.get('dialCode').setValue(splitedPhoneNumber.dialCode);
    }

    this.helperService.setKeyboardInputConfig(this.config);
  }

  resetInput(): void {
    this.helperService.clearKeyboardInput(true, this.config.key);
    this.phoneNumberForm.get('number').reset();
  }

  focusInput(): void {
    setTimeout(() => {
      this.phone_number_input.nativeElement.focus();
    }, 0);
  }

  /**
   * Returns validation error message according to the current thrown error.
   *
   * @returns Validation error message.
   */
  getErrorMessage(error: string): string {
    return getErrorMessage(error);
  }

  ngOnDestroy(): void {
    if (this.phoneNumberChangesSub) this.phoneNumberChangesSub.unsubscribe();
  }
}
