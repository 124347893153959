<div class="ticker-main" *ngIf="tickerDataLoaded">
  <div class="ticker_over">
    <span [innerHTML]="'mainpage_ticker_fixed_no1' | translate"></span>
  </div>

  <div class="marquee-container">
    <div class="marquee anim1">
      <ng-container *ngFor="let ticker of tickerList">
        <div class="ticker_one">
          <div class="ticker_logo">
            <img [src]="ticker.tickerLogoUrl" />
          </div>
          <div class="seperator"></div>
          <div class="ticker_data">
            <span [innerHTML]="ticker.t_text | translate"></span>
            <span [innerHTML]="ticker.t_link | translate" (click)="saveAndRedirect(ticker.id)"></span>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="marquee anim2">
      <ng-container *ngFor="let ticker of tickerList">
        <div class="ticker_one">
          <div class="ticker_logo">
            <img [src]="ticker.white_friendly_logo" />
          </div>
          <div class="seperator"></div>
          <div class="ticker_data">
            <span [innerHTML]="ticker.t_text | translate"></span>
            <span [innerHTML]="ticker.t_link | translate" (click)="saveAndRedirect(ticker.id)"></span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
